import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormControl} from '@angular/forms';
import {debounceTime, map, Observable, of, startWith, Subject, switchMap} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {UserInstitution} from '../../models/user-institution';
import {InstitutionService} from "../../services/institution.service";

@Component({
  selector: 'app-user-institution-dropdown',
  templateUrl: './user-institution-dropdown.component.html',
  styleUrls: ['./user-institution-dropdown.component.css']
})
export class UserInstitutionDropdown implements OnInit, OnChanges, OnDestroy {
  @Input() label: string = '';
  @Input() userId: number | null;
  @Input() selectedUserInstitutions: UserInstitution[] = [];
  @Output() selectionChange = new EventEmitter<UserInstitution[]>();

  userInstitutionMultiCtrl: FormControl = new FormControl();
  userInstitutionMultiFilterCtrl: FormControl = new FormControl();
  filteredUserInstitutions: Observable<UserInstitution[]>
  isLoading: boolean = false;
  onDestroy = new Subject<void>();

  constructor(private institutionService: InstitutionService) {}

  ngOnInit() {
    this.initializeFilter();
    this.userInstitutionMultiCtrl.setValue(this.selectedUserInstitutions);

    this.userInstitutionMultiCtrl.valueChanges.pipe(takeUntil(this.onDestroy)).subscribe(selectedInstitutions => {
      this.selectionChange.emit(selectedInstitutions);
      this.selectedUserInstitutions = [...selectedInstitutions];
    });
  }

  initializeFilter() {
    this.filteredUserInstitutions = this.userInstitutionMultiFilterCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(200),
      switchMap(search => this.filterUserInstitutions(search)),
    );
  }

  filterUserInstitutions(searchQuery: string): Observable<UserInstitution[]> {
    if (!searchQuery) {
      return of(this.selectedUserInstitutions);
    }

    this.isLoading = true;
    return this.institutionService.searchUserInstitutions(this.userId, searchQuery).pipe(
      map(results => {
        this.isLoading = false;

        const resultIds = new Set(results.map(ui => ui.doctorCode + ui.institutionName));
        const filteredSelected = this.selectedUserInstitutions.filter(si =>
          (si.doctorCode.toLowerCase().includes(searchQuery.toLowerCase())) &&
          !resultIds.has(si.doctorCode + si.institutionName)
        );
        return [...results, ...filteredSelected];
      }),
      startWith([])
    );
  }

  displayDoctorCodeWithInstitution(userInstitution: UserInstitution) {
    const institution = userInstitution.institutionName ? userInstitution.institutionName : userInstitution.institutionCode;
    return userInstitution.doctorCode + ' - ' + institution;
  }

  compareUserInstitutions(ui1: UserInstitution, ui2: UserInstitution): boolean {
    return ui1 && ui2 ? ui1.userId + ui1.institutionCode === ui2.userId + ui2.institutionCode : ui1 === ui2;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userInstitutions) {
      this.initializeFilter();
    }

    if (changes.selectedUserInstitutions) {
      this.userInstitutionMultiCtrl.setValue(this.selectedUserInstitutions);
    }
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}